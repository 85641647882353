import $ from 'jquery'
import { onPageChange } from '../extensions/dom_ready'
// import 'focus-options-polyfill' // ensures focus({ preventScroll: true }) works in IE

export const handleDataFocus = (el) => {
  let fieldToFocus
  el = $(el)
  const selector = el.data('focus')
  if (selector === 'this') {
    fieldToFocus = el
  } else if (selector === 'first-field') {
    fieldToFocus = $('input:visible, textarea:visible', el[0]).first()

    // The button selector should not be focused when first element as there would be an active/focused element already based on the attribute value
    if (fieldToFocus.parents('.button-selectors').length) {
      fieldToFocus = null
    }
  } else if (selector === 'none') {
    // Do nothing!
  } else {
    fieldToFocus = $(selector)
  }

  // Don't do anything if the focus is already there, this seems to intermittently mess up system tests.
  if (fieldToFocus && !fieldToFocus.is(':focus')) {
    // fieldToFocus[0]? to ensure we perform focus on an HTMLElement
    return fieldToFocus[0]?.focus({ preventScroll: true })
  }
}

onPageChange('focus form field', () =>
  $('[data-focus]:first').each(function () {
    return handleDataFocus($(this))
  })
)
